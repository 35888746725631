import './css/app.css'
import './css/auth.css'
import './css/nav.css'
import './css/custom.scss'
import './css/datepicker.scss'
import 'react-modern-drawer/dist/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
// import 'bootstrap'

import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { Provider, useDispatch } from 'react-redux'
import { useMsal } from '@azure/msal-react'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import { loginRequest } from './resources/auth-config'

import './i18n'
import './resources/firebase'

import /*{ openAPI, signedAPI, ENDPOINTS } from */ './api/api'
import store from './reducers'
import auth from './reducers/auth'

import Main from './navigation'
import UpdateBox from './components/UpdateBox'

const MSALApp = () => {
    const dispatch = useDispatch()
    const { instance, accounts } = useMsal()
    const [isLoaded, setIsLoaded] = useState(false)
    const location = useLocation()
    const { hash } = location

    useEffect(() => {
        const callback = instance.addEventCallback((message) => {
            // console.log(message)
            switch (message.eventType) {
                case 'msal:initializeStart':
                    break
                case 'msal:initializeEnd':
                    break
                case 'msal:accountAdded':
                    break
                case 'msal:accountRemoved':
                    break
                case 'msal:loginStart':
                    break
                case 'msal:loginSuccess':
                    dispatch(auth.setMSALResponse(message.payload))
                    break
                case 'msal:loginFailure':
                    break
                case 'msal:acquireTokenStart':
                    break
                case 'msal:acquireTokenSuccess':
                    dispatch(auth.setMSALResponse(message.payload))
                    break
                case 'msal:acquireTokenFailure':
                    instance.logoutRedirect().catch((e) => {
                        console.error(e)
                    })
                    break
                case 'msal:acquireTokenFromNetworkStart':
                    break
                case 'msal:ssoSilentStart':
                    break
                case 'msal:ssoSilentSuccess':
                    break
                case 'msal:ssoSilentFailure':
                    break
                case 'msal:acquireTokenByCodeStart':
                    break
                case 'msal:acquireTokenByCodeSuccess':
                    break
                case 'msal:acquireTokenByCodeFailure':
                    break
                case 'msal:handleRedirectStart':
                    break
                case 'msal:handleRedirectEnd':
                    break
                case 'msal:popupOpened':
                    break
                case 'msal:logoutStart':
                    break
                case 'msal:logoutSuccess':
                    break
                case 'msal:logoutFailure':
                    break
                case 'msal:logoutEnd':
                    break
                default:
                    break
            }
        })

        return () => instance.removeEventCallback(callback)
    }, [instance, dispatch])

    useEffect(() => {
        const request = {
            scopes: [],
            forceRefresh: true,
            account: accounts[0],
        }

        const loadToken = async () => {
            try {
                try {
                    const redirect = await instance.handleRedirectPromise()
                    const response = redirect || (await instance.acquireTokenSilent(request))
                    dispatch(auth.setMSALResponse(response))
                } catch (re) {
                    const isForgot = re.message?.includes('AADB2C90118')
                    if (isForgot) {
                        const forgotURL = [
                            ...process.env.REACT_APP_AUTHORITY?.split('/').slice(0, -1),
                            'B2C_1A_PASSWORDRESET',
                        ].join('/')

                        instance.loginRedirect({ scopes: [], authority: forgotURL })
                    }
                }
            } catch (e) {
                console.log(e)
            } finally {
                setIsLoaded(true)
            }
        }

        loadToken()
    }, [instance, accounts, hash, dispatch])

    return isLoaded ? <Main /> : null
}

const App = () => {
    const [stack, setStack] = useState(null)

    const FallbackComponent = ({ error, stack, resetErrorBoundary }) => {
        return (
            <div className='container py-3'>
                <button className='btn btn-danger' onClick={resetErrorBoundary}>
                    Try again
                </button>
                <h3>An error occurred. Copy text in the window and send it to the developer</h3>
                <div className='card p-3'>
                    <h5>{error.message}</h5>
                    {stack?.split('\n')?.map((v, i) => (
                        <div key={i}>
                            <code>{v}</code>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    return (
        <ErrorBoundary
            FallbackComponent={(props) => <FallbackComponent {...props} stack={stack} />}
            onReset={() => {
                setStack(null)
                window.location.reload()
            }}
            onError={(error, info) => {
                setStack(info?.componentStack)
                // debugger
                // console.log(error?.stack)
            }}
        >
            <Provider store={store}>
                <>
                    <MSALApp />
                    <UpdateBox />
                    <ToastContainer theme='colored' />
                </>
            </Provider>
        </ErrorBoundary>
    )
}

export default App
