export const Enrich = ({ className = '', children = '' }) => {
    if (!children?.includes('[[') && !children?.includes('https:') && !children?.includes('\n'))
        return <div className={className}>{children}</div>

    // [[ANY TEXT][className]]

    const regex = /\[\[([^[\]]+)\]\[([^[\]]+)\]\]/g
    const withStyles = children.replace(regex, '<span class="$2">$1</span>').replace(/\n/g, '<br />')
    const urlRegex = /https?:\/\/[^\s/$.?#].[^\s]*/g

    const withLinks = withStyles.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`
    })

    return <div className={className} dangerouslySetInnerHTML={{ __html: withLinks }} />
}

export default Enrich
