import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    faEnvelope,
    faBars,
    faEllipsis,
    faPencil,
    faPlus,
    faFilter,
    faXmark,
    faArrowDownAZ,
    faClock,
    faInfoCircle,
    faLock,
    faFolder,
    faTrashAlt,
    faUser,
    faUsers,
    faGlobe,
    faDownload,
    faCodeBranch,
    faArrowsLeftRight,
    faRedo,
    faFileArrowUp,
    faExclamationTriangle,
    faCheck,
    faArrowRightLong,
    faArrowLeft,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faPrint,
} from '@fortawesome/free-solid-svg-icons'

import { faFile, faEye, faEyeSlash, faCircleCheck } from '@fortawesome/free-regular-svg-icons'

import { ReactComponent as Logo } from './svg/logo.svg'
import { ReactComponent as Basket } from './svg/basket.svg'
import { ReactComponent as Bottles } from './svg/bottles.svg'
import { ReactComponent as Case } from './svg/case.svg'
import { ReactComponent as Document } from './svg/document.svg'
import { ReactComponent as Certificate } from './svg/certificate.svg'
import { ReactComponent as Signature } from './svg/signature.svg'
import { ReactComponent as SignatureSmall } from './svg/signature-solid.svg'

export { Logo, Basket, Bottles, Case, Document, Certificate, Signature, SignatureSmall }

export const Envelope = (props) => <FontAwesomeIcon icon={faEnvelope} {...props} />
export const Bars = (props) => <FontAwesomeIcon icon={faBars} {...props} />
export const Ellipsis = (props) => <FontAwesomeIcon icon={faEllipsis} {...props} />
export const Pencil = (props) => <FontAwesomeIcon icon={faPencil} {...props} />
export const Plus = (props) => <FontAwesomeIcon icon={faPlus} {...props} />
export const Filter = (props) => <FontAwesomeIcon icon={faFilter} {...props} />
export const XMark = (props) => <FontAwesomeIcon icon={faXmark} {...props} />
export const SortAZ = (props) => <FontAwesomeIcon icon={faArrowDownAZ} {...props} />
export const Clock = (props) => <FontAwesomeIcon icon={faClock} {...props} />
export const InfoCircle = (props) => <FontAwesomeIcon icon={faInfoCircle} {...props} />
export const Lock = (props) => <FontAwesomeIcon icon={faLock} {...props} />
export const Folder = (props) => <FontAwesomeIcon icon={faFolder} {...props} />
export const TrashAlt = (props) => <FontAwesomeIcon icon={faTrashAlt} {...props} />
export const User = (props) => <FontAwesomeIcon icon={faUser} {...props} />
export const Users = (props) => <FontAwesomeIcon icon={faUsers} {...props} />
export const Globe = (props) => <FontAwesomeIcon icon={faGlobe} {...props} />
export const Upload = (props) => <FontAwesomeIcon icon={faFileArrowUp} {...props} />
export const Download = (props) => <FontAwesomeIcon icon={faDownload} {...props} />
export const CodeBranch = (props) => <FontAwesomeIcon icon={faCodeBranch} {...props} />
export const ArrowsLeftRight = (props) => <FontAwesomeIcon icon={faArrowsLeftRight} {...props} />
export const Reload = (props) => <FontAwesomeIcon icon={faRedo} {...props} />
export const File = (props) => <FontAwesomeIcon icon={faFile} {...props} />
export const EyeOpen = (props) => <FontAwesomeIcon icon={faEye} {...props} />
export const EyeClosed = (props) => <FontAwesomeIcon icon={faEyeSlash} {...props} />
export const Warning = (props) => <FontAwesomeIcon icon={faExclamationTriangle} {...props} />
export const CircleCheck = (props) => <FontAwesomeIcon icon={faCircleCheck} {...props} />
export const Check = (props) => <FontAwesomeIcon icon={faCheck} {...props} />
export const ArrowRightLong = (props) => <FontAwesomeIcon icon={faArrowRightLong} {...props} />
export const ArrowLeft = (props) => <FontAwesomeIcon icon={faArrowLeft} {...props} />
export const MagnifyingGlassPlus = (props) => <FontAwesomeIcon icon={faMagnifyingGlassPlus} {...props} />
export const MagnifyingGlassMinus = (props) => <FontAwesomeIcon icon={faMagnifyingGlassMinus} {...props} />
export const Print = (props) => <FontAwesomeIcon icon={faPrint} {...props} />
